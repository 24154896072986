<template>
    <div class="patient-login" :style="{ backgroundImage: 'url(' + require('@/assets/img/patient-bg.jpg') + ')' }">
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" :visible="signIn" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px">
            <template #default="{ }">
                <div class="p-3" v-loading="loading">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <h4 class="text-center">{{$t('general.login_your_account')}}</h4>
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onLogin)">
                            <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="loginData.email">
                            </base-input>

                            <div class="password-div">
                                <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPassword?'text':'password'" :placeholder="$t('profile.password')" v-model="loginData.password">
                                </base-input>
                                <div class="showPassword" @click="showPass">
                                    <span v-if="!showPassword">
                                        <i class="fas fa-eye"></i>
                                    </span>
                                    <span v-else>
                                        <i class="fas fa-eye-slash"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="text-center">
                                <base-button type="primary" block native-type="submit" class="my-4" :disabled="invalid">{{$t('general.login')}}</base-button>
                                <base-button type="link text-primary" @click.prevent="forgetForm">{{$t('general.forger_password')}}</base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                    <div class="text-divider">
                        <span>{{$t('general.or')}}</span>
                    </div>
                    <base-button type="secondary" block class="my-4" @click="loginWithFacebook">
                        <i class="fab fa-facebook facebook"></i> {{$t('general.facebook_login')}}
                    </base-button>
                    <GoogleLogin :params="googleParams" :onSuccess="googleSuccess" :onFailure="googleFailure" class="btn base-button my-4 btn-secondary btn-block">
                        <i class="fab fa-google google"></i> {{$t('general.google_login')}}
                    </GoogleLogin>
                    <hr>
                    <div class="text-center">
                        <small>
                            <span>{{$t('general.have_not_account')}}
                                <a href="#" @click.prevent="signUpForm" class="link text-primary">{{$t('general.signup')}}!</a>
                            </span>
                        </small>
                    </div>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" :visible="signUp" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px">
            <template #default="{ }">
                <div class="p-3" v-loading="loading">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <h4 class="text-center">{{$t('general.register')}}</h4>
                    <base-button type="secondary" block class="my-4" @click="registerWithFacebook">
                        <i class="fab fa-facebook facebook"></i> {{$t('general.facebook_register')}}
                    </base-button>
                    <GoogleLogin :params="googleParams" :onSuccess="googleRegisterSuccess" :onFailure="googleFailure" class="btn base-button my-4 btn-secondary btn-block">
                        <i class="fab fa-google google"></i> {{$t('general.google_register')}}
                    </GoogleLogin>
                    <div class="text-divider">
                        <span>{{$t('general.or')}}</span>
                    </div>
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onRegister)">
                            <base-input alternative class="mb-3" name="name" :nameAs="$t('profile.name')" :rules="{required: true}" prepend-icon="fas fa-user" :placeholder="$t('profile.name')" v-model="registerData.name">
                            </base-input>

                            <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="registerData.email">
                            </base-input>

                            <div class="password-div">
                                <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPassword?'text':'password'" :placeholder="$t('profile.password')" v-model="registerData.password">
                                </base-input>
                                <div class="showPassword" @click="showPass">
                                    <span v-if="!showPassword">
                                        <i class="fas fa-eye"></i>
                                    </span>
                                    <span v-else>
                                        <i class="fas fa-eye-slash"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="terms" required>
                                <label class="form-check-label" for="terms" v-html="$options.filters.getTermLinks($t('general.signup_terms'), {terms: $t('general.terms_of_use'), terms_link: $t('general.terms_of_use_link'), privacy_policy: $t('general.privacy_policy'), privacy_policy_link: $t('general.privacy_policy_link'), frontendUrl})">
                                </label>
                            </div>
                            <div class="text-center">
                                <base-button type="primary" block native-type="submit" class="my-4" :disabled="invalid">{{$t('general.signup')}}</base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                    <hr>
                    <div class="text-center">
                        <small>
                            <span>{{$t('general.have_account')}} <br>
                                <a href="#" @click.prevent="signInForm" class="link text-primary">{{$t('general.signin')}}!</a>
                            </span>
                        </small>
                    </div>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" :visible="forgetPassword" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px">
            <template #default="{ }">
                <div class="p-3" v-loading="loading">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <h4 class="text-center">{{$t('general.forgot_password')}}</h4>
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onForget)">
                            <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="forgetData.email">
                            </base-input>

                            <div class="text-center">
                                <base-button type="primary" block native-type="submit" class="my-4" :disabled="invalid">{{$t('general.reset_pass')}}</base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                    <hr>
                    <div class="text-center">
                        <small>
                            <span>{{$t('general.have_account')}} <br>
                                <a href="#" @click.prevent="signInForm" class="link text-primary">{{$t('general.signin')}}!</a>
                            </span>
                        </small>
                        <div class="text-divider">
                            <span>{{$t('general.or')}}</span>
                        </div>
                        <a href="#" @click.prevent="signUpForm" class="link text-primary">
                            <small>{{$t('general.signup')}}!</small>
                        </a>
                    </div>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px" :visible="resetPassword">
            <template #default="{ }">
                <div class="p-3">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <template v-if="!resetPassData.error">
                        <h4 class="text-center">{{$t('general.reset_your_password')}}</h4>
                        <validation-observer v-slot="{handleSubmit, valid}" ref="formValidator">
                            <b-form role="form" @submit.prevent="handleSubmit(onChangePass)">
                                <div class="password-div">
                                    <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6, confirmed:'confirmation'}" prepend-icon="fas fa-unlock-alt" :type="showPasswordRemember.first?'text':'password'" :placeholder="$t('profile.password')" v-model="resetPassData.password">
                                    </base-input>
                                    <div class="showPassword" @click="showPassRemember('first')">
                                        <span v-if="!showPasswordRemember.first">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="password-div">
                                    <base-input alternative class="mb-3" name="passwordRepeat" :nameAs="$t('profile.password_repeat')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPasswordRemember.second?'text':'password'" :placeholder="$t('profile.password_repeat')" vid="confirmation" v-model="resetPassData.passwordRepeat">
                                    </base-input>
                                    <div class="showPassword" @click="showPassRemember('second')">
                                        <span v-if="!showPasswordRemember.second">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <base-button type="primary" block native-type="submit" class="my-4" :disabled="!valid || resetPassData.password!=resetPassData.passwordRepeat">{{$t('general.change_pass')}}</base-button>
                                </div>
                            </b-form>
                        </validation-observer>
                        <hr>
                        <div class="text-center">
                            <small>
                                <span>
                                    <a href="#" @click.prevent="signInForm" class="link text-primary">{{$t('general.signin')}}!</a>
                                </span>
                            </small>
                        </div>
                    </template>
                    <template v-else>
                        <b-alert variant="warning" show>{{$t('error.invalid_reset_code')}}</b-alert>
                        <base-button type="link text-primary" @click.prevent="forgetForm">{{$t('general.forger_password')}}</base-button>
                    </template>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>
<script>
import { AUTH_REQUEST, PATIENT_REGISTER, AUTH_FORGET, AUTH_CHANGEPASS } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { setCookie, getCookie } from '@/utils/cookie';
import _ from 'lodash';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import GoogleLogin from 'vue-google-login';
import { initFbsdk } from '@/utils/facebook';
import { mapGetters } from 'vuex';
let self;

export default {
    components: {
        GoogleLogin
    },
    computed: {
        ...mapGetters({
            clinic: 'getClinic',
        })
    },
    data() {
        return {
            imgUrl: process.env.VUE_APP_CDNURL + '/clinic/',
            redirect: null,
            googleParams: {
                client_id: '1095235567303-qkisg3i8u29hrc9uckh2h1b2f1ta60fp.apps.googleusercontent.com'
            },
            frontendUrl: process.env.VUE_APP_FRONTEND,
            signIn: false,
            signUp: false,
            forgetPassword: false,
            resetPassword: false,
            loading: false,
            loginData: {
                email: '',
                password: '',
                google: false,
                facebook: false,
                type: 'patient'
            },
            registerData: {
                name: '',
                email: '',
                password: ''
            },
            forgetData: {
                email: '',
                type: 'patient'
            },
            resetPassData: {
                uuid: '',
                password: '',
                passwordRepeat: '',
                type: 'patient',
                error: false
            },
            showPassword: false,
            showPasswordRemember: {
                first: false,
                second: false
            }
        };
    },
    methods: {
        showPassRemember(id) {
            this.showPasswordRemember[id] = this.showPasswordRemember[id] !== true;
        },
        showPass() {
            self.showPassword = self.showPassword !== true;
        },
        registerWithFacebook() {
            window.FB.getLoginStatus(function(response) {
                if (response.status != 'connected') {
                    window.FB.login(
                        function(response) {
                            if (response.status == 'connected') {
                                window.FB.api('/me?fields=id,name,email', function(response) {
                                    let registerData = {
                                        name: response.name,
                                        email: response.email,
                                        password: response.id + new Date().getTime(),
                                        google: false,
                                        facebook: true,
                                        type: 'patient'
                                    };

                                    self.onRegister(registerData);
                                });
                            }
                        },
                        { scope: 'public_profile,email' }
                    );
                } else {
                    self.loginWithFacebook();
                }
            });
        },
        loginWithFacebook() {
            window.FB.getLoginStatus(function(response) {
                if (response.status == 'connected') {
                    window.FB.api('/me?fields=id,name,email', function(response) {
                        let loginData = {
                            email: response.email,
                            password: response.id,
                            google: false,
                            facebook: true,
                            type: 'patient'
                        };
                        self.onLogin(loginData);
                    });
                } else {
                    window.FB.login(response => {
                        self.loginWithFacebook();
                    }, this.params);
                }
            });
        },
        googleRegisterSuccess(googleUser) {
            let profile = googleUser.getBasicProfile();
            let registerData = {
                name: `${profile.AT} ${profile.wR}`,
                email: profile.zt,
                password: profile.QR + new Date().getTime(),
                google: true,
                facebook: false,
                type: 'patient'
            };

            self.onRegister(registerData);
        },
        googleSuccess(googleUser) {
            self.loading = true;
            let profile = googleUser.getBasicProfile();
            let loginData = {
                email: profile.zt,
                password: profile.QR,
                google: true,
                facebook: false,
                type: 'patient'
            };
            self.onLogin(loginData);
        },
        googleFailure(error) {
            console.log(error);
            self.$notify({ type: 'danger', message: self.$i18n.t('error.google_failure') });
        },
        signUpForm() {
            self.resetPassword = false;
            self.forgetPassword = false;
            self.signIn = false;
            self.signUp = true;
        },
        signInForm() {
            self.resetPassword = false;
            self.forgetPassword = false;
            self.signUp = false;
            self.signIn = true;
        },
        forgetForm() {
            self.resetPassword = false;
            self.signUp = false;
            self.signIn = false;
            self.forgetPassword = true;
        },
        resetPassForm() {
            self.signUp = false;
            self.signIn = false;
            self.forgetPassword = false;
            self.resetPassword = true;
        },
        onLogin(loginData = false) {
            self.loading = true;
            let formData = _.cloneDeep(loginData ? loginData : self.loginData);
            const clinic = getCookie('user-clinic');
            if(clinic){
                formData.clinic = clinic;
            }

            self.$store
                .dispatch(AUTH_REQUEST, formData)
                .then(resp => {
                    //console.log('auth_resp', resp)
                    if(self.redirect){
                        location.href = self.redirect;
                    }else{
                        let appointment = getCookie('appointment') || null;
                        if (appointment) {
                            let aUrl = appointment.split('_');
                            location.href = `${self.frontendUrl}/r/${aUrl[0]}/${aUrl[1]}`;
                        } else {
                            self.$router.push('/');
                        }
                    }
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        onRegister(registerData = false) {
            self.loading = true;
            self.$store
                .dispatch(PATIENT_REGISTER, registerData ? registerData : self.registerData)
                .then(resp => {
                    self.loading = false;
                    self.signInForm();

                    let loginData = _.clone(self.loginData);
                    let regData = registerData ? _.clone(registerData) : _.clone(self.registerData);
                    loginData['email'] = regData['email'];
                    loginData['password'] = regData['password'];

                    self.onLogin(loginData);
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        onForget() {
            self.loading = true;
            self.$store
                .dispatch(AUTH_FORGET, self.forgetData)
                .then(resp => {
                    if (resp) {
                        self.$notify({ type: 'success', message: self.$i18n.t('success.password_reset_link_send') });
                    }
                    self.loading = false;
                    self.forgetData.email = '';
                    self.signInForm();
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        onChangePass() {
            self.loading = true;
            self.$store
                .dispatch(AUTH_CHANGEPASS, self.resetPassData)
                .then(resp => {
                    if (resp) {
                        self.$notify({ type: 'success', message: self.$i18n.t('success.password_reset') });
                    }
                    location.href = '/patient-login';
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        }
    },
    filters: {
        getTermLinks(text, translation) {
            let termsText = text.replace('%dterms%d', '<a href="' + translation.frontendUrl + '/' + translation.terms_link + '" target="_new">' + translation.terms + '</a>');
            termsText = termsText.replace(
                '%dprivacy_policy%d',
                '<a href="' + translation.frontendUrl + '/' + translation.privacy_policy_link + '" target="_new">' + translation.privacy_policy + '</a>'
            );

            return '<small>' + termsText + '</small>';
        }
    },
    mounted() {
        self = this;

        if(this.$route.query.redirect){
            self.redirect = this.$route.query.redirect;
        }

        setCookie('user_login_type', 'patient', '1y');

        initFbsdk();
        if (self.$route.name == 'patientregister') {
            self.signUpForm();
        } else if (self.$route.name == 'patientforget') {
            if (self.$route.query.code) {
                this.resetPassData.uuid = self.$route.query.code;
            } else {
                self.resetPassData.error = true;
            }
            self.resetPassForm();
        } else {
            self.signInForm();
        }
    }
};
</script>
